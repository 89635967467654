<template>
  <div class="fundo fill-height" :style="fundo">
    <v-container fixed fill-height>
      <v-layout align-center justify-center row>
        <v-flex class="pa-5">
          <v-row>
            <v-col cols="6" offset="3">
              <v-img                       
              height="40"
              contain            
              :src="require('@/assets/logo-dark.svg')">
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6"  offset-md="3">
              <v-card class="pa-5" flat>            
                <v-form @submit.prevent="login" ref="form" validate>              
                  <v-card-text>
                    <v-layout row>
                      <v-flex xs12>
                        <v-text-field
                          name="username"
                          label="Login"
                          id="username"
                          v-model="username"
                          type="username"
                          required
                          :rules="fieldRequired"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <v-flex xs12>
                        <v-text-field
                          name="password"
                          label="Senha"
                          id="password"
                          v-model="password"
                          :rules="fieldRequired"
                          required
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="() => (showPassword = !showPassword)"
                          :type="showPassword ? 'password' : 'text'"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn to="/" color="secondary">Cancelar</v-btn>
                    <v-btn type="submit" color="primary">Entrar</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-flex>

        <alerta></alerta>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
//const jwt = require("jsonwebtoken");
import color from 'color'
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      username: "",
      password: "",
      showPassword: true,
      fieldRequired: [
        v => !!v || 'Campo é obrigatório'
      ],
    };
  },
  methods: {
    ...mapActions(["setAlerta"]),
    login() {
      if (!this.$refs.form.validate()){
        return
      }

      var self = this;
      let redirect = self.$route.query.redirect;
      console.log("redirect " + redirect);
      //axios.defaults.headers.common['X-Auth-Token'] = ''
      this.$auth
        .post("/login", { username: this.username, password: this.password })
        .then(function(response) {
          let { access_token } = response.data;
          //var decoded = jwt.decode(access_token);
          //console.log("loged", decoded);
          //console.log("redirect ", redirect, access_token, access_token != null);
          if (access_token) {
            window.localStorage.setItem('tokenMZ2', access_token)          
          }

          self.$router.push({
            path: redirect,
            //query: { access_token: access_token }
          });
          
        })
        .catch((e) => {
          let message = "";
          if(e.response.status == 401) {
            message = "Credenciais não encontradas!";
          } else {
            message = e.response.data.message;
          }

          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: message,
          });
        });
    }
  },
  components: {
    Alerta,
  },
  computed: {
    fundo () {
        let cor = '#061766' 
        let cor1 = color(cor).alpha(1)
        let cor2 = color(cor).alpha(0.5)
        let cor3 = color(cor).alpha(1)
        return `background: linear-gradient(${cor1} 5%, ${cor2} 50%, ${cor3} 95%), url(${require('@/assets/bg-01.jpg')}) center center;`
      }
  },
  created() {
    console.log("redirect ->  " + this.$route.query.redirect);
    let token = window.localStorage.getItem('tokenMZ2')
    if (token) {
      let redirect = this.$route.query.redirect
      this.$store.dispatch('setAppLayout')
      if (!redirect) {
        redirect =  '/'
      }
      this.$router.push({ path: redirect });
    }else {
      this.$store.dispatch('setLoginLayout')
    }
  }
};
</script>

<style>
.fundo {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
</style>
